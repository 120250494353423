<template>
  <main class="Users">
    <TheSubHeader
      icon="profile"
      title="Operadores"
      page-title-tag="Users"
      type="filter"
      :actions="canAddUsers"
      organizer-gap="1rem"
      organizer-columns="3fr repeat(2, 1fr)" 
    >
      <template #beforeButtons>
        <AsyncExportButton
          v-if="permissions && permissions.users && permissions.users.exportUsersButton"
          name="operators"
          name-report="Relatorio_de_Operadores"
          icon="export"        
          color="dark"
          label="Exportar"
          label-loading="Exportando relatório"
          :track="trackSubheaderButtonString('Users', 'Export')"
        />
        <BaseButton
          v-if="permissions && permissions.users && permissions.users.groupPageButton"
          color="dark"
          icon="user"
          label="Gerenciar grupos"
          class="operation-button"
          :track="trackSubheaderButtonString('Users', 'Groups')"
          @click="goTo('/groups')"
        />
      </template>
      <template #filter>
        <!-- Driver -->
        <BaseTreeSelect
          v-model="filter.searchValue"
          name="Nome do Usuário"
          label="Nome do Usuário"
          placeholder="Selecione"
          multiple
          suggestions="searchUsersWithGroups"
          @input="resetSearch()"
        />
        <!-- Lojista -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filter.storeSelected"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="resetSearch()"
        />
        <!-- User Type -->
        <BaseTreeSelect
          v-model="filter.typeSelected"
          name="Tipo"
          label="Tipo"
          placeholder="Selecione"
          multiple
          :select-options="groupsSelect"
          @input="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="Users__Content">
      <div class="Users__Table">
        <DataTable
          :header="users.data.headers"
          :table="users.data.content"
          is-sticky
          @remove="removeUser"
          @edit="editUser"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>
    <Dialog
      v-if="show"
      warning="Tem certeza que deseja deletar esse operador?"
      label-cancel="Não"
      label-confirm="Sim"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
    />
  </main>
</template>

<script>
import { BaseTreeSelect, BaseButton } from '@/components/atoms'
import { Pagination, Dialog, DataTable, FiltersTag, AsyncExportButton } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Users',
  components: {
    DataTable,
    BaseTreeSelect,
    Pagination,
    TheSubHeader,
    FiltersTag,
    Dialog,
    AsyncExportButton,
    BaseButton
  },

  data() {
    return {
      filter:{
        searchValue: [],
        storeSelected: [],
        typeSelected: []
      },
      allTags: [],
      show: false
    }
  },

  computed:{
    ...mapState({
      stores: state => state.stores.stores,
      users: state => state.users.users,
      queryString: state => state.queryString.queryString,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      permissions: state => state.auth.permissions,
      groupsSelect: state => state.groups.groupsSelect,

    }),

    canAddUsers() {
      return this.permissions?.users?.addNewButton ? ['addnew'] : [] 
    }
  },

  mounted(){
    this.fetchGroupSelect()
    this.search()
    if(this.$route.params.type) this.setGroupTypeFilter()
  },

  methods:{
    ...mapActions({
      fetchUsers: 'users/fetchUsers',
      fetchGroupSelect: 'groups/fetchGroupSelect',
      deleteUser: 'users/deleteUser',
      setParameters: 'queryString/setParameters',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
    }),

    setGroupTypeFilter() {
      const group = this.groupsSelect.find(el => el.name.toLowerCase() === this.$route.params.type.toLowerCase())
      this.filter.typeSelected = [group]
    },

    editUser(data){
      this.openInNewTab(`/users/${data.id}`)
    },

    removeUser(data){
      this.userId = data.id
      this.show = true
    },

    confirmDelete(){
      this.deleteUser({ id: this.userId })
      this.show = false
    },

    cancelDelete(){
      this.userId = null
      this.show = false
    },

    updateTags(){
      this.allTags = [...this.filter.searchValue, ...this.filter.storeSelected, ...this.filter.typeSelected]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filter.searchValue = this.filter.searchValue.filter((tag) => tag.name !== name)
      this.filter.storeSelected = this.filter.storeSelected.filter((tag) => tag.name !== name)
      this.filter.typeSelected = this.filter.typeSelected.filter((tag) => tag.name !== name)
      

      this.resetSearch()
    },

    clearAllTags(){
      this.allTags = []
      this.filter.searchValue = []
      this.filter.storeSelected = []
      this.filter.typeSelected = []

      this.resetSearch()
    },

    resetSearch() {
      this.clearPagination(this.paginate).then(() => this.search())
    },

    search(){
      this.$nextTick(() => {
        const params = {
          obj: {
            id: this.filter.searchValue,
            'filter[stores][store_id]': this.$refs.lojista.checkSelectAll ? [] : this.filter.storeSelected,
            'filter[roles][role_id]': this.filter.typeSelected
          },
          paginate: this.paginate,
          page: this.page
        }

        this.updateTags()

        this.setParameters(this.filter)
        this.fetchUsers(params).then(() => this.setPagination(this.users))
      })
    },

    goTo(route) {
      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss" scoped>
.Users {
  &__Content{
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }
  &__Table{
    grid-area: main;
    margin-bottom: 50px;
  }
}
</style>